import settings from './settings';

const config = {
  ...settings,
  pageTitle: {
    titlePrefix: import.meta.env.VITE_PAGE_TITLE_PREFIX,
    siteName: 'CCC',
  },
  Backend: {
    url: import.meta.env.VITE_BACKEND_URL,
  },
  Socket: {
    url: import.meta.env.VITE_SOCKET_URL,
    refreshTokenUrl: import.meta.env.VIE_SOCKET_REFRESH_TOKEN_URL,
    debugEnabled: true,
  },
  Sentry: {
    dns: import.meta.env.VITE_SENTRY_DNS,
    environment: import.meta.env.VITE_SENTRY_ENV,
  },
  Login: {
    signInUrl: '/api/v1/auth/sign-in',
    deviceName: 'web_client',
    homeUrl: '/maps',
    loginUrl: '/login',
    redirectUrl: '',
    forgotPasswordUrl: '/forgot-password',
  },
  Player: {
    enableOnOnlineMap: true,
    debug: {
      showPositionIndex: import.meta.env.VITE_PLAYER_DEBUG_SHOW_POSITION_INDEX === 'true',
    },
  },
  selectedVehiclesLimit: {
    enabled: true,
    limit: 700,
  },
  recoil: {
    debug: false,
  },
  appStatus: {
    name: import.meta.env.VITE_APP_STATUS_NAME,
    url: import.meta.env.VITE_APP_STATUS_URL,
    isEnabled: import.meta.env.VITE_APP_STATUS_ENABLED === 'true',
  },
  apiService: {
    detailed500Error: {
      isEnabled: import.meta.env.VITE_DETAILED_500_ERROR_IS_ENABLED === 'true',
      showRequestUrl: import.meta.env.VITE_DETAILED_500_ERROR_SHOW_REQUEST_URL === 'true',
    },
  },
  onlineHelp: {
    isEnabled: import.meta.env.VITE_ONLINE_HELP_IS_ENABLED !== 'false',
  },
  mucha: import.meta.env.VITE_MUCHA,
  maintenanceToken: import.meta.env.VITE_MAINTENANCE_TOKEN,
  analytics: {
    baseUrl: import.meta.env.VITE_ANALYTICS_BASE_URL,
    srcUrl: import.meta.env.VITE_ANALYTICS_SRC_URL,
    siteId: import.meta.env.VITE_ANALYTICS_SITE_ID,
    disabled: import.meta.env.VITE_ANALYTICS_DISABLED === 'true',
  },
  widgets: {
    FuelLevelPercentageWidget: {
      enableGraph: true,
    },
    FuelLevelLitersWidget: {
      enableGraph: true,
    },
    BatteryWidget: {
      enableGraph: true,
    },
    AdBlueWidget: {
      enableGraph: true,
    },
  },
  festiveIcons: {
    christmas: import.meta.env.VITE_ENABLE_CHRISTMAS_ICONS === 'true',
  },
};

export default config;
