import * as React from 'react';
import { useSetRecoilState } from 'recoil';
import { ICustomer, selectedTempCustomersAtom } from '../../../store/recoil/customers';
import {
  addSelectedTempCustomer,
  removeSelectedTempCustomer,
} from '../../Customers/CustomerService';
import useAnalytics from '../../../hooks/useAnalytics';

interface INavigatorSelectCustomersCustomerProps {
  customer: ICustomer;
  selected: boolean;
  alteredRowStyle: string;
  selectedServerId: number | null;
}

export default function Customer(props: INavigatorSelectCustomersCustomerProps): JSX.Element {
  const { trackEvent } = useAnalytics()
  const setSelectedTempCustomer = useSetRecoilState<number[]>(selectedTempCustomersAtom);

  const handleSelectCustomer = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.currentTarget;
    const customerId = Number(value);
    trackEvent(['Select customers', 'click', 'Select customer', customerId]);

    if (checked) {
      setSelectedTempCustomer((oldState: number[]) =>
        addSelectedTempCustomer(oldState, customerId)
      );
    } else {
      setSelectedTempCustomer((oldState: number[]) =>
        removeSelectedTempCustomer(oldState, customerId)
      );
    }
  };

  // const isDisabled = (serverId: number | null): boolean => {
  //   if (serverId === null) {
  //     return false;
  //   }
  //   return !(props.selectedServerId === props.customer.serverId);
  // };

  console.log('props: ', props.customer);

  return (
    <div
      className={`e-checkbox px-3 py-2 d-block text-black font-weight-normal ${props.alteredRowStyle}`}
    >
      <label htmlFor={`checkbox-customer-${props.customer.id}`} style={{ width: '400px' }}>
        <input
          id={`checkbox-customer-${props.customer.id}`}
          type="checkbox"
          value={props.customer.id}
          checked={props.selected}
          onChange={handleSelectCustomer}
          // disabled={isDisabled(props.selectedServerId)}
          data-cy={`customer-checkbox-${props.customer.id}`}
        />
        <span className="pl-7" data-cy={`customer-name-${props.customer.id}`}>
          {props.customer.name}
        </span>
      </label>
      <span style={{ float: 'right' }}>{props.customer.salesChannel.shortName}</span>
    </div>
  );
}
