import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { useIntl } from 'react-intl';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  OnChangeFn,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { keepPreviousData, useQueryClient, useInfiniteQuery } from '@tanstack/react-query';
import { useVirtualizer } from '@tanstack/react-virtual';
import './cmdTable.scss';
import { CmdLoader } from '@commander-services/gui-components';
import { JsonSchema } from 'json-schema-library';
import { ICmdTableFilter, ICmdTableSortFilter } from './types';
import useDragScroll from './hooks/useDragScroll';
import { getTableData } from './CmdTableService';
import { selectedVehiclesAtom } from '../../store/recoil/vehicles';
import { selectedCustomersAtom } from '../../store/recoil/customers';
import { filterForRequestAtomFamily, sortFilterAtomFamily } from './CmdTableState';

interface ICmdTableProps<T, D> {
  name: string;
  columns: ColumnDef<D>[];
  columnOrder: string[];
  columnVisibility: Record<string, boolean>;
  // fetchSize?: number;
  stickyColumns?: string[];
  lockedColumns?: string[];
  rowHeight?: number;
  onSelectedRowsChange?: (rows: D[], tableCount: number) => void;
  jsonSchema?: JsonSchema;
  leftStickyColumn?: string[];
  customNoDataMessage?: {
    title: string;
    text: string;
  };
  // isSelectedVehicleRequired?: boolean;
}

const ROW_HEIGHT_DEFAULT = 40;

function usePrevious<T>(value: T): T | undefined {
  const ref = React.useRef<T>();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function CmdTableWithSelectedVehicles<T, D>(props: ICmdTableProps<T, D>) {
  const { formatMessage: f } = useIntl();
  // we need a reference to the scrolling element for logic down below
  const tableContainerRef = React.useRef<HTMLDivElement>(null);
  // const [sorting, setSorting] = React.useState<SortingState>([]);
  const filterForRequest = useRecoilValue<ICmdTableFilter>(filterForRequestAtomFamily(props.name));
  const queryClient = useQueryClient();
  const sortFilter = useRecoilValue<ICmdTableSortFilter>(sortFilterAtomFamily(props.name));
  const selectedCustomers = useRecoilValue(selectedCustomersAtom);
  const selectedVehicles = useRecoilValue(selectedVehiclesAtom);
  // const tableFiltersKey = useRecoilValue(CmdTableState.tableFilters(props.name));

  const onMouseDown = useDragScroll(tableContainerRef);

  const { columns, columnOrder, columnVisibility, onSelectedRowsChange, jsonSchema } = props;
  const observerRef = React.useRef<IntersectionObserver | null>(null);
  const prevSelectedVehicles: number[] | undefined = usePrevious(selectedVehicles);
  const prevSelectedCustomers: number[] | undefined = usePrevious(selectedCustomers);

  const isDataRequestEnabled = (): boolean => {
    let enabled = true;
    // if (props.isSelectedVehicleRequired && selectedVehicles.length === 0) {
    //   enabled = false;
    // }
    if (selectedVehicles.length === 0) {
      enabled = false;
    }
    // if (
    //   prevSelectedCustomers &&
    //   prevSelectedCustomers.length !== selectedCustomers.length // &&
    // ) {
    //   enabled = true;
    // }
    // if (
    //   prevSelectedCustomers &&
    //   !selectedCustomers.every((customerId: number) => prevSelectedCustomers.includes(customerId))
    // ) {
    //   enabled = true;
    // }
    return enabled;
  };

  const updatedFilterForRequest = { ...filterForRequest };
  if (selectedVehicles.length > 0) {
    updatedFilterForRequest.vehicle = selectedVehicles;
  }
  if (
    prevSelectedCustomers &&
    prevSelectedCustomers.length !== selectedCustomers.length // &&
    // selectedCustomers.length === 0
  ) {
    queryClient.resetQueries({ queryKey: [props.name] });
  }
  if (
    // props.isSelectedVehicleRequired &&
    prevSelectedVehicles &&
    prevSelectedVehicles.length > 0 &&
    selectedVehicles.length === 0
  ) {
    updatedFilterForRequest.vehicle = [];
    // const queryKeys = [
    //   props.name,
    //   updatedFilterForRequest, // refetch when sorting changes
    //   sortFilter, // refetch when sorting changes
    //   selectedCustomers, // refetch when selected customers change
    //   selectedVehicles, // refetch when selected vehicles change
    // ];
    queryClient.resetQueries({ queryKey: [props.name] });
  }

  // react-query has a useInfiniteQuery hook that is perfect for this use case
  const queryKeys = [
    props.name,
    updatedFilterForRequest, // refetch when sorting changes
    sortFilter, // refetch when sorting changes
    selectedCustomers, // refetch when selected customers change
    selectedVehicles, // refetch when selected vehicles change
  ];

  const { data, fetchNextPage, isFetching, isLoading, hasNextPage } = useInfiniteQuery({
    queryKey: queryKeys,
    queryFn: async ({ pageParam = 0 }) => {
      const requestData = {
        offset: pageParam,
        filter: {
          ...updatedFilterForRequest,
        },
        sort: { ...sortFilter },
      };
      const fetchData = await getTableData<T>(props.name, requestData, jsonSchema);
      return fetchData;
    },
    initialPageParam: 0,
    // getNextPageParam: (_lastGroup, groups) => groups.length,
    getNextPageParam: (lastPage, allPages) => {
      const totalFetched = allPages.flatMap((page) => page?.records).length;

      return totalFetched < lastPage?.count ? totalFetched : undefined;
    },
    // placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: isDataRequestEnabled(),
    gcTime: 0,
  });

  // flatten the array of arrays from the useInfiniteQuery hook
  const flatData = React.useMemo(() => data?.pages?.flatMap((page) => page?.records) ?? [], [data]);
  const totalDBRowCount: number = data?.pages?.[0]?.count ?? 0;
  // const totalFetched = flatData.length;

  // called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  // const fetchMoreOnBottomReached = React.useCallback(
  //   (containerRefElement?: HTMLDivElement | null) => {
  //     if (containerRefElement) {
  //       const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
  //       // once the user has scrolled within 500px of the bottom of the table, fetch more data if we can
  //       if (
  //         scrollHeight - scrollTop - clientHeight < 500 &&
  //         !isFetching &&
  //         totalFetched < totalDBRowCount
  //       ) {
  //         fetchNextPage();
  //       }
  //     }
  //   },
  //   [fetchNextPage, isFetching, totalFetched, totalDBRowCount]
  // );

  // // a check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  // React.useEffect(() => {
  //   fetchMoreOnBottomReached(tableContainerRef.current);
  // }, [fetchMoreOnBottomReached]);

  const table = useReactTable({
    data: flatData,
    columns,
    state: {
      columnOrder,
      columnVisibility,
    },
    initialState: {
      columnOrder,
      columnPinning: {
        right: props.stickyColumns ?? [],
        left: props.leftStickyColumn ?? [],
      },
      columnVisibility,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: true,
    debugTable: true,
    onColumnOrderChange: (newOrder) => table.setColumnOrder(newOrder),
    onColumnPinningChange: (newPinning) => table.setColumnPinning(newPinning),
  });

  const { rows } = table.getRowModel();

  React.useEffect(() => {
    const selectedRowModels = table.getSelectedRowModel().rows.map((row) => row.original);
    if (onSelectedRowsChange) onSelectedRowsChange(selectedRowModels, totalDBRowCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getSelectedRowModel().rows]);

  const rowVirtualizer = useVirtualizer({
    count: flatData.length,
    estimateSize: React.useCallback(() => props.rowHeight || ROW_HEIGHT_DEFAULT, [props.rowHeight]), // estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    // measure dynamic row height, except in firefox because it measures table border height incorrectly
    // measureElement:
    //   typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
    //     ? (element) => element?.getBoundingClientRect().height
    //     : undefined,
    overscan: 15,
    onChange: (virtualizer) => {
      // measure the table height when the first row is rendered
      if (flatData.length > 0 && virtualizer.getTotalSize() === 0) {
        virtualizer.measure();
      }
    },
  });

  const setIntersectionObserver = React.useCallback(
    (node: Element | HTMLTableRowElement | null) => {
      if (isFetching) return;
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });
      if (node) observerRef.current.observe(node);
    },
    [fetchNextPage, hasNextPage, isFetching]
  );

  // scroll to top of table when sorting changes
  const handleSortingChange: OnChangeFn<SortingState> = (_updater) => {
    // setSorting(updater);
    if (table.getRowModel().rows.length) {
      rowVirtualizer.scrollToIndex?.(0);
    }
  };

  // since this table option is derived from table row model state, we're using the table.setOptions utility
  table.setOptions((prev) => ({
    ...prev,
    onSortingChange: handleSortingChange,
  }));

  const getHeaderClassName = (
    columnId: string,
    isPinned: boolean | string,
    isStickyLeft: boolean
  ) => {
    if (isPinned && props.stickyColumns?.[0] === columnId) return 'first-sticky';
    if (isStickyLeft) return 'left-sticky';
    return '';
  };

  const getRowClassName = (columnId: string, isPinned: boolean | string, isStickyLeft: boolean) => {
    return `${isPinned ? 'sticky-col' : ''} ${
      isPinned && props.stickyColumns?.[0] === columnId ? 'first-sticky' : ''
    } ${isStickyLeft ? 'left-sticky' : ''}`;
  };

  if (isLoading) {
    return <CmdLoader inContent />;
  }

  const getTableHeight = () => {
    const windowHeight = window.innerHeight;
    if (windowHeight && windowHeight >= 600) {
      return `${windowHeight - 250}px`;
    }
    return '600px';
  };

  return (
    <div>
      <div className="table-results-total">
        {`${f({ id: 'table.results.total' })}:`}
        <strong>{` ${totalDBRowCount}`}</strong>
      </div>
      <div>
        {/* ({flatData.length} of {totalDBRowCount} rows fetched) */}

        <div
          className="cmd-table cmd-table-border"
          // onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
          onMouseDown={onMouseDown}
          ref={tableContainerRef}
          style={{
            overflow: 'auto', // our scrollable table container
            position: 'relative', // needed for sticky header
            height: getTableHeight(), // should be a fixed height
          }}
          id="cmd-table"
        >
          {/* Even though we're still using sematic table tags, we must use CSS grid and flexbox for dynamic row heights */}
          <table style={{ display: 'grid' }} className="m-0 border-0">
            <thead
              style={{
                display: 'grid',
                position: 'sticky',
                top: 0,
                zIndex: 1,
                // width: '100%',
              }}
              id="cmd-table-header"
            >
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  style={{
                    display: 'flex',
                    width: '100%',
                    height: props.rowHeight || ROW_HEIGHT_DEFAULT,
                  }}
                >
                  {headerGroup.headers.map((header) => {
                    const isStickyLeft =
                      props.leftStickyColumn?.includes(header.column.id) ?? false;
                    return (
                      <th
                        key={header.id}
                        style={{
                          display: 'flex',
                          width: header.getSize(),
                          position: `${header.column.getIsPinned() ? 'sticky' : 'relative'}`,
                          right:
                            header.column.getIsPinned() === 'right'
                              ? `${header.column.getAfter('right')}px`
                              : undefined,
                          left: isStickyLeft ? `${header.column.getAfter('left')}px` : undefined,
                          zIndex: isStickyLeft || header.column.getIsPinned() ? 2 : 1,
                          // opacity: header.column.getIsPinned() ? 1 : 0.95,
                          // flex: `${
                          //   header.column.id === PARKING_TAG_MODIFIED_AT_KEY ? 1 : '0 1 auto'
                          // }`,
                          flex: `${
                            !props.stickyColumns?.includes(header.column.id) &&
                            !props.lockedColumns?.includes(header.column.id)
                              ? 1
                              : '0 1 auto'
                          }`,
                        }}
                        className={getHeaderClassName(
                          header.column.id,
                          header.column.getIsPinned(),
                          isStickyLeft
                        )}
                        // className="cmd-table-actions"
                      >
                        {/* <div
                        {...{
                          className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      > */}
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {/* </div> */}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody
              style={{
                display: 'grid',
                height: `${rowVirtualizer.getTotalSize()}px`, // tells scrollbar how big the table is
                position: 'relative', // needed for absolute positioning of rows
              }}
            >
              {rowVirtualizer.getVirtualItems().length === 0 && (
                <tr
                  className="p-6 d-flex align-items-center d-flex align-items-center text-center"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '360px',
                    fontSize: '24px',
                    backgroundColor: '#FFFFFF',
                  }}
                >
                  <div data-cy={`cmd-table-${props.name}-no-data`} style={{ color: '#B3B3B3' }}>
                    {props.customNoDataMessage ? (
                      <>
                        {props.customNoDataMessage.title || f({ id: 'table.noData.title' })}
                        <br />
                        {props.customNoDataMessage.text ||
                          f({ id: 'table.noDataWithoutNavigator' })}
                      </>
                    ) : (
                      <>
                        {f({ id: 'table.noData.title' })}
                        <br />
                        {f({ id: 'table.noDataWithoutNavigator' })}
                      </>
                    )}
                  </div>
                </tr>
              )}
              {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                const row = rows[virtualRow.index] as Row<D>;
                return (
                  <tr
                    data-index={virtualRow.index} // needed for dynamic row height measurement
                    // ref={(node) => rowVirtualizer.measureElement(node)} // measure dynamic row height
                    ref={(node) => {
                      // Here you set the ref to link the virtual item to the DOM node
                      rowVirtualizer.measureElement(node);
                      if (virtualRow.index === flatData.length - 1) {
                        // Last row
                        setIntersectionObserver(node); // Now correctly setting the observer
                      }
                    }}
                    key={row.id}
                    style={{
                      display: 'flex',
                      position: 'absolute',
                      transform: `translateY(${virtualRow.start}px)`, // this should always be a `style` as it changes on scroll
                      width: '100%',
                      height: `${virtualRow.size}px`, // dynamic row height
                    }}
                  >
                    {row.getVisibleCells().map((cell) => {
                      const isStickyLeft =
                        props.leftStickyColumn?.includes(cell.column.id) ?? false;
                      return (
                        <td
                          key={cell.id}
                          style={{
                            display: 'flex',
                            width: cell.column.getSize(),
                            position: `${cell.column.getIsPinned() ? 'sticky' : 'relative'}`,
                            right:
                              cell.column.getIsPinned() === 'right'
                                ? `${cell.column.getAfter('right')}px`
                                : undefined,
                            left: isStickyLeft ? `${cell.column.getAfter('left')}px` : undefined,
                            // zIndex: cell.column.getIsPinned() ? 10 : 1,
                            // opacity: cell.column.getIsPinned() ? 1 : 0.95,
                            // flex: `${
                            //   cell.column.id === PARKING_TAG_MODIFIED_AT_KEY ? 1 : '0 1 auto'
                            // }`,
                            flex: `${
                              !props.stickyColumns?.includes(cell.column.id) &&
                              !props.lockedColumns?.includes(cell.column.id)
                                ? 1
                                : '0 1 auto'
                            }`,
                          }}
                          className={getRowClassName(
                            cell.column.id,
                            cell.column.getIsPinned(),
                            isStickyLeft
                          )}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {isFetching && <div className="table-fetching-text">{f({ id: 'waypoints.loading' })}</div>}
      </div>
    </div>
  );
}
