import localforage from 'localforage';
import { atom, selector } from 'recoil';
import localForageEffect from './effects/localForageEffect';
import setSelectedCustomersToSocketServerEffect from './effects/setSelectedCustomersToSocketServerEffect';

export const getSelectedCustomersFromIndexedDb = async (): Promise<number[]> => {
  const selectedCustomers = await localforage.getItem<number[]>('selectedCustomers');
  if (!selectedCustomers) {
    return [];
  }
  return selectedCustomers;
};

export interface ICustomer {
  id: number;
  name: string;
  shortName: string;
  label: string | null;
  salesChannel: {
    id: number;
    shortName: string;
  }
  serverId?: number;
}

export const customersAtom = atom<ICustomer[]>({
  key: 'customersState_customers',
  default: [],
});

export const selectedCustomersAtom = atom<number[]>({
  key: 'customersState_selectedCustomers',
  default: [],
  effects: [localForageEffect('selectedCustomers'), setSelectedCustomersToSocketServerEffect()],
});

export const selectedTempCustomersAtom = atom<number[]>({
  key: 'customersState_selectedTempCustomers',
  default: [],
});

export const selectedCustomersWithDataSelector = selector<ICustomer[]>({
  key: 'customersState_selectedCustomersWithData',
  get: ({ get }) => {
    const ids = get(selectedCustomersAtom);
    const customersList = get(customersAtom);
    const data: ICustomer[] = [];
    ids.forEach((customerId: number) => {
      const customer = customersList.find((item: ICustomer) => item.id === customerId);
      if (customer) {
        data.push(customer);
      }
    });
    return data;
  },
});

export const filteredCustomersAtom = atom<ICustomer[]>({
  key: 'customersState_filteredCustomers',
  default: selector({
    key: 'customersForFilter',
    get: ({ get }) => {
      return get(customersAtom);
    },
  }),
});
