import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

interface CustomTooltipProps {
  content: React.ReactNode;
  show: boolean;
  children: React.ReactNode;
  position: { top: number; left: number };
  backgroundColor?: string;
  style?: React.CSSProperties;
  width?: string;
  truncateClassName?: string;
}

export default function CustomTooltip({
  content,
  show,
  children,
  backgroundColor,
  style,
  width,
  position,
  truncateClassName,
}: CustomTooltipProps) {
  const [tooltipPos, setTooltipPos] = useState(position || { top: 0, left: 0 });
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (position) {
      setTooltipPos(position);
    }
  }, [position]);

  const tooltipStyle = {
    position: 'absolute',
    top: tooltipPos.top + 5,
    left: tooltipPos.left,
    backgroundColor: backgroundColor || '#494949',
    color: '#fff',
    padding: '8px',
    borderRadius: '4px',
    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
    zIndex: 9999,
    maxWidth: width || '300px',
    wordWrap: 'break-word',
    ...style,
  };

  const tooltip =
    show && position
      ? ReactDOM.createPortal(<div style={tooltipStyle}>{content}</div>, document.body)
      : null;

  return (
    <div ref={ref} className={truncateClassName || 'text-cell'}>
      {children}
      {tooltip}
    </div>
  );
}
