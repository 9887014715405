import * as React from 'react';
import { useRecoilState } from 'recoil';
import SimpleSelectFilter from '../Filter/SimpleSelectFilter';
import useFilter from '../hooks/useFilter';
// import * as TLCLiteState from '../TLCLiteState';
import SortButton from './SortButton';
import { tableFilterAtomFamily } from '../CmdTableState';

interface IThSimpleSelectProps {
  id: string;
  tableName: string;
  title: string;
  isSortable?: boolean;
  // minWidth?: string;
  // maxWidth?: string;
  tooltip?: string;
}

function ThSimpleSelect(props: IThSimpleSelectProps): JSX.Element | null {
  const [tableActionsFilters, setTableActionsFilters] = useRecoilState(
    tableFilterAtomFamily({ [props.tableName]: props.id })
  );
  const { getFilterData } = useFilter(props.tableName);

  const handleOnClick = (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>
  ) => {
    event.preventDefault();
    const { id } = event.currentTarget;

    setTableActionsFilters(
      (tableActionFilter) =>
        tableActionFilter && {
          ...tableActionFilter,
          id: props.id,
          isOpen: true,
          isActionChipsOpen: false,
        }
    );

    if (id) {
      if (!tableActionsFilters) {
        getFilterData(props.tableName, props.id);
      }
    }
  };

  const renderSimpleSelect = (): JSX.Element | null => {
    if (
      tableActionsFilters &&
      tableActionsFilters.isOpen &&
      !tableActionsFilters.isActionChipsOpen &&
      tableActionsFilters.data
    ) {
      return (
        <SimpleSelectFilter
          id={props.id}
          tableName={props.tableName}
          filterValues={tableActionsFilters.data}
          title={props.title}
        />
      );
    }

    return null;
  };

  return (
    <div
      className={`${tableActionsFilters && tableActionsFilters.values ? 'active' : ''}`}
      style={{
        // minWidth: props.minWidth || '150px',
        // maxWidth: props.maxWidth || '300px',

        // flex: 1,
        // whiteSpace: 'nowrap',
        cursor: 'pointer',
      }}
      title={props.tooltip}
    >
      <div className="cmd-table-header">
        <div
          id={props.id}
          onClick={handleOnClick}
          className="text-truncate cmd-table-header-text"
          data-cy={`filter-button-text-${props.id}`}
        >
          {props.title}
        </div>
        <div className="cmd-table-header-actions">
          <button
            id={props.id}
            type="button"
            className="filter cmd-icons cmd-icon-filter"
            onClick={handleOnClick}
            data-cy={`filter-button-${props.id}`}
          >
            <span className="sr-only">Filter</span>
          </button>
          {props.isSortable && <SortButton id={props.id} tableName={props.tableName} />}
        </div>
        {renderSimpleSelect()}
      </div>
    </div>
  );
}

export default React.memo(ThSimpleSelect);
