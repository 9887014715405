import localforage from 'localforage';
import { ICookieConsent } from '../components/CookieConsent/interfaces';
import { IUserMapSettings } from '../components/OnlineMapNew/types';
import { TMapType } from '../interfaces/map';
import { UserResponse } from '../components/UserData/types';

const EVENTS_KEY = 'events';

export const getUsername = (): string | null => {
  return localStorage.getItem('username');
};

export const getCustomerId = (): number | null => {
  const customerId = localStorage.getItem('customerId');
  if (!customerId) {
    return null;
  }
  return Number(customerId);
};

export const getUserId = (): number | null => {
  const userId = localStorage.getItem('userId');
  if (!userId) {
    return null;
  }
  return Number(userId);
};

export const getDriverId = (): number | null => {
  const driverId = localStorage.getItem('driverId');
  if (!driverId) {
    return null;
  }
  return Number(driverId);
};

export const getUserIdFromLocalForage = async (): Promise<number | null> => {
  const userId = await localforage.getItem<number>('userId');
  if (!userId) {
    return null;
  }
  return Number(userId);
};

export const getDriverIdFromLocalForage = async (): Promise<number | null> => {
  const driverId = await localforage.getItem<number>('driverId');
  if (!driverId) {
    return null;
  }
  return Number(driverId);
};

export const getUserEmail = (): string | null => {
  const userEmail = localStorage.getItem('userEmail');
  if (!userEmail) {
    return null;
  }
  return userEmail;
};

export const setUserId = (id: number): void => {
  localforage.setItem('userId', Number(id));
  return localStorage.setItem('userId', String(id));
};

export const setDriverIdInIndexedDb = async (id: number | null): Promise<void> => {
  await localforage.setItem('driverId', id ? Number(id) : 0);
};

export const setDriverId = (id: number | null): void => {
  return localStorage.setItem('driverId', String(id));
};

export const setUserEmail = (email: string): void => {
  return localStorage.setItem('userEmail', email);
};

export const setUsername = (username: string): void => {
  return localStorage.setItem('username', username);
};

export const setDefaultCustomer = (selectedCustomers: number[]): void => {
  return localStorage.setItem('defaultCustomer', JSON.stringify(selectedCustomers));
};

export const getDefaultCustomer = (): number[] => {
  const defaultCustomer = localStorage.getItem('defaultCustomer');
  if (defaultCustomer) {
    return JSON.parse(defaultCustomer);
  }
  return [];
};

export const setCustomerId = (customerId: number): void => {
  return localStorage.setItem('customerId', String(customerId));
};

export const setLanguage = (language: string): void => {
  return localStorage.setItem('language', language);
};

export const getLanguage = (): string => {
  const language = localStorage.getItem('language');

  if (language) {
    return language;
  }

  return '';
};

export const setSpeedLimitSettings = (settings: Record<string, any>): void => {
  return localStorage.setItem('speedLimitSettings', JSON.stringify(settings));
};

export const getSpeedLimitSettings = (): any => {
  const speedLimitSettings = localStorage.getItem('speedLimitSettings');
  if (speedLimitSettings) {
    return JSON.parse(speedLimitSettings);
  }
  console.warn("Configuration for speedlimits doesn't exists.");
  return null;
};

export const setMapSettings = (settings: IUserMapSettings): void => {
  if (!settings.value.mapType) {
    settings.value.mapType = 'roadmap';
  }
  return localStorage.setItem('mapSettings', JSON.stringify(settings));
};

export const getMapSettings = (): IUserMapSettings => {
  const mapSettings = localStorage.getItem('mapSettings');

  if (mapSettings) {
    const tempMapSettings: IUserMapSettings = JSON.parse(mapSettings);
    const events = JSON.parse(mapSettings).value.events;

    if (!tempMapSettings.value.mapType) {
      tempMapSettings.value.mapType = 'roadmap';
    }

    if (typeof events === 'boolean' || !(EVENTS_KEY in tempMapSettings.value)) {
      tempMapSettings.value.events = {
        enabled: true,
        doors: true,
        additional: true,
        bluecoins: true,
      };
      tempMapSettings.value.clusters = true;

      return tempMapSettings;
    }

    return JSON.parse(mapSettings);
  }

  return {
    customerId: null,
    id: null,
    key: 'map_settings',
    value: {
      identification: true,
      licenceNumber: true,
      driverName: true,
      vehicleName: true,
      bluecoinName: true,
      alarms: true,
      events: {
        enabled: true,
        doors: true,
        additional: true,
        bluecoins: true,
      },
      clusters: true,
      mapType: 'roadmap',
    },
  };
};

export const setCookieConsent = (cookieConsent: ICookieConsent | null): void => {
  localStorage.setItem('cookieConsent', cookieConsent ? JSON.stringify(cookieConsent) : '');
};

export const getCookieConsent = (): ICookieConsent | null => {
  const cookieConsent = localStorage.getItem('cookieConsent');
  return cookieConsent ? JSON.parse(cookieConsent) : null;
};

export const logOut = (): void => {
  const language = getLanguage();
  localStorage.clear();
  localforage.clear();
  setLanguage(language);
  window.location.href = '/';
};

export const autoLogout = (message: string): void => {
  const language = getLanguage();
  localStorage.clear();
  localforage.clear();
  localStorage.setItem('autologoutMessage', message);
  setLanguage(language);
  // window.location.href = '/?autologout=true';
  window.location.href = '/';
};

export const isLoggedIn = (): boolean => {
  return !(
    localStorage.getItem('socketServerToken') === null &&
    localStorage.getItem('appPermisions') === null
  );
};

export const setEmail = (email: string | null): void => {
  return localStorage.setItem('email', email ?? '');
};

export const setUserData = async (user: UserResponse): Promise<void> => {
  const userData = {
    username: user.username,
    email: user.email,
    id: user.id,
  };
  await localforage.setItem('user', userData);
  setUsername(user.username); // replace with indexedDb
  setEmail(user.email); // replace with indexedDb
  setUserId(user.id); // replace with indexedDb
  setDriverId(user.driverId);
  await setDriverIdInIndexedDb(user.driverId);
  // setLanguage(user.language);
  setDefaultCustomer(user.selectedCustomers);
  setCustomerId(user.customerId);
  const mapSettings = user.mapSettings
    ? user.mapSettings
    : {
        // customerId: null,
        id: null,
        key: 'map_settings',
        value: {
          alarms: true,
          clusters: true,
          driverName: true,
          events: {
            additional: true,
            enabled: true,
            bluecoins: true,
            doors: true,
          },
          identification: true,
          licenceNumber: true,
          vehicleName: true,
          mapType: 'roadmap' as TMapType,
        },
      };
  setMapSettings(mapSettings);
  if (user.speedLimits) {
    setSpeedLimitSettings(user.speedLimits.value);
  }
  setCookieConsent(user.cookies);
};
