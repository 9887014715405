import { AxiosResponse } from 'axios';
import ApiService from './ApiService';
import { IInput } from './FormikValidatorService';
import { IToastr } from '../components/Toastr/ToastrState';

const RESPONSE_OK = 'ok';
const RESPONSE_ERROR = 'error';

export interface IChangePasswordSuccessResponse {
  emailSent: boolean;
  status: string;
  toast: IToastr;
}

export interface IChangePasswordErrorResponse {
  form: {
    formValidationResult: {
      [index: string]: string;
    };
    inputs: IInput[];
  };
  status: string;
  toast: IToastr;
}

export interface IChangePasswordFormResponse {
  status: string;
  toast: null;
  formValidationResult: {
    [index: string]: string;
  };
  inputs: IInput[];
}

export const getChangePasswordForm = async (): Promise<IChangePasswordFormResponse | null> => {
  const response = await ApiService.get(`/v1/auth/change-password`, {});
  if (response.data && response.data.status === RESPONSE_OK) {
    return response.data.form;
  }
  return null;
};

export const submitChangePasswordForm = async (
  currentPassword: string,
  password: string,
  confirmPassword: string
): Promise<AxiosResponse<IChangePasswordSuccessResponse> | null> => {
  const data = {
    password,
    current_password: currentPassword,
    confirm_password: confirmPassword,
  };
  const response = await ApiService.post('/v1/auth/change-password', data);

  if (!response || (response.data && response.data.status === RESPONSE_ERROR)) {
    return null;
  }
  if (response.data && response.data.status === RESPONSE_OK) {
    return response;
  }
  return null;
};
