import * as React from 'react';

interface IThTextProps {
  title: string;
  // maxWidth?: string;
  // minWidth?: string;
  tooltip?: string;
}

function ThText(props: IThTextProps): JSX.Element {
  return (
    <div
      // className="th-sticky"
      style={{
        whiteSpace: 'nowrap',
        // minWidth: props.minWidth || '150px',
        // maxWidth: props.maxWidth || '300px',
        // flex: '1',
      }}
      title={props.tooltip}
    >
      <div className="cmd-table-header">
        <div className="text-truncate cmd-table-header-text">{props.title}</div>
      </div>
    </div>
  );
}

export default ThText;
